<template>
  <InputText type="text" v-model="data.name" placeholder="Nombre *" />
  <Textarea
    v-model="data.description"
    :autoResize="true"
    rows="5"
    cols="30"
    placeholder="Descripción"
  />

  <div class="a-buttons">
    <Button
      label="Cancelar"
      class="p-button-raised p-button-danger p-button-text"
      @click="cancel"
    />
    <button
      :class="!isLoading ? 'a-main-btn' : 'a-main-btn a-loading-btn'"
      :disabled="isLoading || !isValid"
      @click="createData"
    >
      Aceptar
    </button>
  </div>
  <Toast />
</template>

<script>
import UserAPI from "@/services/users.js";
import SecretariesAPI from "@/services/secretaries.js";
export default {
  name: "NewSecretary",
  data() {
    return {
      data: {
        name: "",
        description: "",
        active: true,
      },
      isLoading: false,
    };
  },
  computed: {
    isValid() {
      return this.data.name != "";
    },
  },
  emits: ["onSuccess", "onExit"],
  methods: {
    cancel() {
      this.$emit("onExit");
    },
    createData() {
      this.isLoading = true;

      SecretariesAPI.create(this.data)
        .then(() => {
          this.$emit("onSuccess");
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.createData();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
  },
};
</script>

<style>
.p-dialog .p-dialog-content .p-inputtext {
  margin-bottom: 2px;
}
</style>
