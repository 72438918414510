<template>
  <main-container>
    <!-- TOP -->
    <template v-slot:header>
      <button class="a-main-btn" @click="settings.addModal = true">
        Agregar
      </button>
      <div class="a-search-container">
        <InputText placeholder="Buscar" v-model="filters['global'].value" />
      </div>
    </template>

    <!-- Table -->
    <DataTable
      :value="dataset"
      :paginator="true"
      :rows="7"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink"
      :filters="filters"
      :globalFilterFields="['name', 'description']"
    >
      <!-- Id field -->
      <Column field="id" header="Id." sortable headerStyle="width: 5rem" />
      <!-- Fields defined in data section -->
      <Column
        v-for="col of fields"
        :field="col.field"
        :header="col.header"
        :key="col.field"
        sortable
      >
      </Column>

      <!-- Color field -->
      <Column header="Tipos de incidente">
        <template #body="slotProps">
          <span>{{ slotProps.data.incidentTypes.length }}</span>
        </template>
      </Column>

      <!-- State field -->
      <Column header="Condición" headerStyle="width: 9rem">
        <template #body="slotProps">
          <div id="a-table-switch">
            <InputSwitch
              v-model="slotProps.data.active"
              @change="onStateChange(slotProps.data)"
            />
          </div>
        </template>
      </Column>

      <!-- Action buttons -->
      <Column headerStyle="width: 9rem">
        <template #body="slotProps">
          <div class="a-container-tab-btns">
            <button
              class="a-icon-tab-btn a-detail-btn"
              @click="detailDataset(slotProps.data)"
            >
              <img :src="require('@/assets/icons/eye-white.svg')" />
            </button>
            <button
              class="a-icon-tab-btn a-edit-btn"
              @click="editDataset(slotProps.data)"
            >
              <img :src="require('@/assets/icons/tool-white.svg')" />
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
  </main-container>

  <Dialog
    header="Crear secretaría"
    v-model:visible="settings.addModal"
    v-if="settings.addModal"
    :modal="true"
    :breakpoints="{ '960px': '75vw' }"
    :style="{ width: '50vw' }"
  >
    <Create @onSuccess="datasetAdded" @onExit="settings.addModal = false" />
  </Dialog>

  <Dialog
    v-model:visible="settings.detailModal"
    v-if="settings.detailModal"
    :modal="true"
    :breakpoints="{ '960px': '75vw' }"
    :style="{ width: '50vw' }"
  >
    <template #header>
      <h3>
        Secretaría
        <span class="a-id-badge" v-if="settings.selected != null">{{
          settings.selected.id
        }}</span>
      </h3>
    </template>
    <DetailOrEdit
      :item="settings.selected"
      :isEdit="settings.isEdit"
      @onSuccess="datasetEdited"
      @onExit="
        settings.detailModal = false;
        settings.isEdit = false;
      "
    />
  </Dialog>
</template>

<script>
import MainContainer from "../components/templates/MainContainer.vue";
import Create from "@/components/secretary/NewSecretary.vue";
import DetailOrEdit from "@/components/secretary/DetailSecretary.vue";
import SecretariesAPI from "@/services/secretaries.js";
import UserAPI from "@/services/users.js";
import { FilterMatchMode } from "primevue/api";
export default {
  components: { MainContainer, Create, DetailOrEdit },
  name: "Secretaries",
  data() {
    return {
      dataset: [],
      fields: [
        {
          field: "name",
          header: "Nombre",
        },
      ],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      settings: {
        addModal: false,
        detailModal: false,
        selected: null,
      },
    };
  },
  methods: {
    getDataset() {
      SecretariesAPI.index()
        .then((response) => {
          this.dataset = response.data;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.getBuilders();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
    datasetAdded() {
      this.settings.addModal = false;
      this.getDataset();
      this.$toast.add({
        severity: "success",
        detail: "Se ha añadido un nuevo objeto",
        life: 3000,
      });
    },
    detailDataset(data) {
      this.settings.selected = data;
      this.settings.detailModal = true;
    },
    editDataset(data) {
      this.settings.selected = data;
      this.settings.detailModal = true;
      this.settings.isEdit = true;
    },
    datasetEdited() {
      this.settings.detailModal = false;
      this.settings.isEdit = false;
      this.settings.builder = null;
      this.getDataset();
      this.$toast.add({
        severity: "success",
        detail: "Se ha editado la información.",
        life: 3000,
      });
    },
    onStateChange(data) {
      SecretariesAPI.updateState(data)
        .then(() => {
          this.$toast.add({
            severity: "success",
            detail: "Se ha actualizado el estado de la información.",
            life: 3000,
          });
          this.getDataset();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.onStateChange(data);
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
  },
  mounted() {
    this.getDataset();
  },
};
</script>

<style></style>
