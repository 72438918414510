import Axios from "axios";

let http = "https://app.gobiernocontigo.com/api/v1/";

export default {
  index() {
    return Axios.get(`${http}secretary/`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },
  showActive() {
    return Axios.get(`${http}secretary/`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },

  // show(id) {},

  update(id, data) {
    return Axios.put(`${http}secretary/${id}/`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },

  create(data) {
    return Axios.post(`${http}secretary/`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },

  delete(data) {
    data.active = false;
    return Axios.put(`${http}secretary/${data.id}/`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },

  updateState(data) {
    return Axios.put(`${http}secretary/${data.id}/`, data, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("cfta")}`,
      },
    });
  },
};
